/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}
.form-control {
  height: calc(1.5em + 0.75rem + 4px) !important;
}
.form-control:focus {
  border-color: var(--theme-deafult) !important;
  outline-color: var(--theme-deafult) !important;
  outline-width: 1px !important;
  outline-style: auto !important;
  box-shadow: none !important;
}
textarea.form-control {
  height: auto !important;
}

// CHANGE STYLE IN SELECTOR
.ng-select.ng-select-single .ng-select-container {
  height: 38px !important;
}
.input-group-prepend + * .ng-select.ng-select-container > input,
.input-group-prepend + * .ng-select-container,
.input-group-prepend + * .select2-selection,
.input-group-prepend + * .select2-selection:focus {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: 1px solid #ced4da !important;
}

button > svg {
  vertical-align: middle !important;
}



@font-face {
  font-family: "GloberRegular";
  src: url("assets/fonts/cbb/89245ca9b70a03d9ca18f53c917cfc3f.eot"); /* IE9*/
  src: url("assets/fonts/cbb/89245ca9b70a03d9ca18f53c917cfc3f.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("assets/fonts/cbb/89245ca9b70a03d9ca18f53c917cfc3f.woff2") format("woff2"), /* chrome、firefox */
  url("assets/fonts/cbb/89245ca9b70a03d9ca18f53c917cfc3f.woff") format("woff"), /* chrome、firefox */
  url("assets/fonts/cbb/89245ca9b70a03d9ca18f53c917cfc3f.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}

// a > span {
//   font-family: "GloberRegular" !important;
//   font-size: 16px;
//   font-style: normal;
//   -webkit-font-smoothing: antialiased;
//   -webkit-text-stroke-width: 0.2px;
//   -moz-osx-font-smoothing: grayscale;
// }



.logo-app {
  padding-bottom: 0.5em;
}

.btn-cbb-g {
  background-color: #00a54e !important;
  font-size: 14px !important;
  border-color: transparent !important;
}
.btn-cbb-b {
  background-color: #001b72 !important;
  font-size: 14px !important;
  border-color: transparent !important;
}

.bgc-cbb-g {
  background-color: #00a74a !important;
}
.bgc-cbb-b {
  background-color: #001a72 !important;
}

.txt-cbb-wb {
  color: #fff !important;
  font-weight: bold !important;
}

.swal2-confirm {
  background-color: #00a74a !important;
  font-size: 14px !important;
  border-color: transparent !important;
}

.swal2-cancel {
  background-color: #001b72 !important;
  font-size: 14px !important;
  border-color: transparent !important;
}

.btn-cbb-bi {
  background-color: #001a72 !important;
  font-size: 14px !important;
  border-color: transparent !important;
}
.btn-cbb-gi {
  background-color: #00a74a !important;
  font-size: 14px !important;
  border-color: transparent !important;
}
.icon-btn {
  width: 40px;
  height: 60px;
}

.btn-descargar-documento {
  background-color: #e4002b;
}

.btn-cbb-g-a {
  background-color: #00a54e !important;
  font-size: 14px !important;
  border-color: transparent !important;
}
.btn-cbb-b-a {
  background-color: #001b72 !important;
  font-size: 14px !important;
  border-color: transparent !important;
}

*{
  font-family: "GloberRegular" ;
  
}
.btn-cbb-r {
  background-color: #e4002b !important;
  font-size: 14px !important;
  border-color: transparent !important;
  // border-radius: 40px;
}


.bootstrap3-info {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .4) !important;
  border: 1px solid #ccc !important;
}
